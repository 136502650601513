import { useCallback } from 'react';
import { useSecuritiesSecurityTransformApi } from '../api';
import { SecuritiesSecurity } from '../../type';
import { lmlGetPositionsTitleCase } from '../../../../accounts';
import { makeBondFullNameTransformFn } from '../../../../bonds/shared/bondFullName';
import { Locale as DateFnsLocale } from 'date-fns';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';
import { useGetDateFnsLocale } from '../../../../../../../Components/getDateLocale';
import { useAtomValue } from 'jotai';
import { pathnameUrlLanguageAtom } from '../../../../../../../history';
import { isBond } from '../utils';

const makeSecuritiesSecurityFullNameTransformFn = (props: {
  locale: string,
  dateFnsLocale: DateFnsLocale,
  intRateFormatter: (value: number) => string,
  maxSIssuerName1Length?: number,
}) => {
  const {
    locale,
    dateFnsLocale,
    intRateFormatter,
    maxSIssuerName1Length,
  } = props;
  return (securitiesSecurity: SecuritiesSecurity | undefined) => {
    if (securitiesSecurity == null) {
      return undefined;
    }

    const sTypeGrpId = securitiesSecurity['S_TYPE_GRP.ID'];
    if (!isBond(sTypeGrpId)) {
      const titleCaser = lmlGetPositionsTitleCase(locale);
      const lml2 = securitiesSecurity['S_ACC.LML2_DESCRIPTION'];
      return titleCaser(lml2);
    }
    return makeBondFullNameTransformFn({
      locale: dateFnsLocale,
      intRateFormatter,
      maxSIssuerName1Length,
    })(securitiesSecurity);
  };
};

const intRateFormat = {
  format: {
    style: 'percent' as const,
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  },
  divider: 100,
};

export const useSecuritiesSecurityFullName = () => {
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  const intRateFormatter = useNumberFormatter(intRateFormat);
  const dateFnsLocale = useGetDateFnsLocale();
  const transformFn = useCallback(makeSecuritiesSecurityFullNameTransformFn({
    locale,
    intRateFormatter,
    dateFnsLocale,
  }), []);
  const response = useSecuritiesSecurityTransformApi(transformFn);
  return response;
};
