import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { getMcGrossOpbDivider } from '../sType';

export const maxQuantityTransformFnBase = (bond: SecuritiesSecurity) => {
  const exRate = bond?.['EXRATE_HIS.EXRATE_GES'];
  const quotMul = bond?.['S_MARKET.QUOT_MULT'];
  const price = bond['S_QUOTE.PRICE_CLOSE'];
  const divider = getMcGrossOpbDivider(bond['S_TYPE.MC_GROSS_OPB']);
  const coupure = Math.max(1000, quotMul);
  const maxEur = 300_000 / ((price ?? divider) / divider);
  const maxCurrency = maxEur / (exRate ?? 1);
  const rangeMax = Math.ceil(maxCurrency / coupure) * coupure;
  return rangeMax;
};

export const maxQuantityTransformFn = (bond: SecuritiesSecurity | undefined) => {
  if (bond == null) {
    return null;
  }
  return maxQuantityTransformFnBase(bond);
};

export const useSecuritiesSecurityMaxQuantity = () => {
  const response = useSecuritiesSecurityTransformApi(maxQuantityTransformFn);
  return response;
};
