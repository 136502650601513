import ouiBase from '@goldwasserexchange/oui';
import { useAccountOrderFieldApiByOpbFordId, useAccountOrderTransformApiByOpbFordId } from '../api';
import { formatNumber } from '../../../../../../../Components/NumberDisplay';
import { useCallback } from 'react';
import { AccountsOrder } from '../../type';
import { useLingui } from '@lingui/react';
import { mcOpbLimiteMessageDescriptions } from './mcOpbLimite';
import { getMcGrossOpbDivider } from '../../../../securities';

export const useAccountOrderOpbFordPrice = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const response = useAccountOrderFieldApiByOpbFordId({
    opbFordDId,
    field: 'OPB_FORD.PRICE',
  });
  return response;
};

export const useAccountOrderTypeAndPriceText = () => {
  const opbFordDId = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const {
    i18n,
  } = useLingui();
  const transformFunction = useCallback(
    (accountOrder: AccountsOrder | undefined) => {
      if (accountOrder == null) {
        return undefined;
      }
      const mcOpbLimite = accountOrder['OPB_FORD.MC_OPB_LIMITE'];
      const message = mcOpbLimiteMessageDescriptions[mcOpbLimite];
      const type = `${message != null ? `${i18n._(message)} ` : ''}`;
      const mcGrossOpb = accountOrder['S_TYPE.MC_GROSS_OPB'];
      const divider = getMcGrossOpbDivider(mcGrossOpb);
      const opbFordPrice = accountOrder['OPB_FORD.PRICE'];
      const currency = accountOrder['C_CURRENCY.CODE'];
      const price = mcOpbLimite === 5302 && opbFordPrice != null ? `(${formatNumber(divider === 100
        ? {
          format: {
            style: 'percent',
          },
          divider,
        }
        : {
          format: {
            style: 'currency',
            currency,
          },
          divider,
        })(opbFordPrice)})` : '';
      return `${type}${price}`;
    },
    [i18n],
  );
  const response = useAccountOrderTransformApiByOpbFordId({
    opbFordDId,
    transformFunction,
  });
  return response;
};
