import { useContext, useMemo } from 'react';
import { SecuritiesSecurityParam } from '../type';
import { securitiesSecurityParamsContext } from '../context';
import { stringifyParams } from '../../../shared/utils/makeUrl';
import { useBondSAccId } from '../../../bonds';

export const useSecuritiesSecurityDefaultParams = (params: Partial<SecuritiesSecurityParam>): Partial<SecuritiesSecurityParam> | null => {
  const {
    'S_ACC.ID': contextSAccId,
  } = useContext(securitiesSecurityParamsContext);
  const bondSAccId = useBondSAccId();
  const sAccId = params['S_ACC.ID'] ?? bondSAccId ?? contextSAccId;
  const defaultParams = useMemo(() => ({
    'S_ACC.ID': sAccId,
  }), [contextSAccId]);
  return defaultParams;
};

export const useSecuritiesSecurityStringifiedParams = () => {
  const contextParams = useSecuritiesSecurityDefaultParams({});
  return contextParams !== null ? stringifyParams(contextParams) : null;
};
