import { makeGrossBondCalculatorWithSettlment } from '../../../../bonds/bond/utils/makeGrossBondCalculator';
import { SecuritiesSecurity } from '../../type';
import { useSecuritiesSecurityTransformApi } from '../api';
import { isBond } from '../utils';

export const useSecurityGrossBondCalculatorWithSettlment = () => {
  const response = useSecuritiesSecurityTransformApi(makeGrossBondCalculatorWithSettlment);
  return response;
};

export const getAccrued = (data: SecuritiesSecurity | undefined) => {
  if (data == null) {
    return undefined;
  }
  if (!isBond(data['S_TYPE_GRP.ID'])) {
    return 0;
  }
  const calculator = makeGrossBondCalculatorWithSettlment(data);
  if (calculator == null) {
    return 0;
  }
  return calculator.accrued;
};

export const useSecuritySecuritiesAccrued = () => {
  const response = useSecuritiesSecurityTransformApi(getAccrued);
  return response;
};
