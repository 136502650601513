import useSWR, { KeyedMutator } from 'swr';
import { makeApiUrl } from '../../../../../../aws';
import { getServiceUrl } from '../../../../shared';
import { Securities } from '../../enum';
import { useSecuritiesSecurityStringifiedParams } from './defaultParams';
import { SecuritiesSecurity } from '../type';
import { fetchWithOptionalAuth } from '../../../shared/utils/fetchWithAuth';
import { ACTOR_DEDUPING_INTERVAL } from '../../../shared';
import { useMemo } from 'react';

const useMakeSecuritiesSecurityUrl = () => {
  const stringifiedParams = useSecuritiesSecurityStringifiedParams();
  const url = stringifiedParams !== null ? `${makeApiUrl('actor')}/${getServiceUrl(Securities.Security)}${stringifiedParams}` : null;
  return url;
};

type BaseUseSecuritiesSecurityApiResponse = {
  isLoading: boolean,
  error?: any,
  mutate: KeyedMutator<SecuritiesSecurity[]>,
  isValidating: boolean,
}

type UseSecuritiesSecurityApiResponse = {
  data: SecuritiesSecurity | undefined,
} & BaseUseSecuritiesSecurityApiResponse

export const useSecuritiesSecurityApi = (): UseSecuritiesSecurityApiResponse => {
  const url = useMakeSecuritiesSecurityUrl();
  const result = useSWR<SecuritiesSecurity[], any>(
    url,
    fetchWithOptionalAuth,
    { dedupingInterval: ACTOR_DEDUPING_INTERVAL },
  );
  const resultWithLoading = useMemo(() => {
    const isLoading = !result.data && !result.error;
    const { data } = result;
    return ({
      ...result,
      data: data ? data[0] : undefined,
      isLoading,
    });
  }, [result]);
  return resultWithLoading;
};

type UseSecuritiesSecurityTransformApiResponse<T> = {
  data: T | undefined,
} & BaseUseSecuritiesSecurityApiResponse

export const useSecuritiesSecurityTransformApi = <T>(transformFn: (securitiesSecurity: SecuritiesSecurity | undefined) => T): UseSecuritiesSecurityTransformApiResponse<T> => {
  const result = useSecuritiesSecurityApi();
  const resultWithLoading = useMemo(() => {
    const { data, isLoading } = result;
    return ({
      ...result,
      data: isLoading ? undefined : transformFn(data),
    });
  }, [result]);
  return resultWithLoading;
};

type UseSecuritiesSecurityFieldApiResponse<T extends keyof SecuritiesSecurity> = {
  data: SecuritiesSecurity[T] | undefined,
} & BaseUseSecuritiesSecurityApiResponse

export const useSecuritiesSecurityFieldApi = <T extends keyof SecuritiesSecurity>(field: T): UseSecuritiesSecurityFieldApiResponse<T> => {
  const result = useSecuritiesSecurityApi();
  const resultWithLoading = useMemo(() => {
    const { data, isLoading } = result;
    return ({
      ...result,
      data: isLoading || !data ? undefined : data[field],
    });
  }, [result, field]);
  return resultWithLoading;
};

export const useSecuritiesSecurityFieldTransformFnApi = <T, Field extends keyof SecuritiesSecurity>(props: {
  field: Field,
  transformFn: (security: SecuritiesSecurity[Field] | undefined) => T,
}): UseSecuritiesSecurityTransformApiResponse<T> => {
  const {
    field,
    transformFn,
  } = props;
  const result = useSecuritiesSecurityApi();
  const resultWithLoading = useMemo(() => {
    const { data, isLoading } = result;
    return ({
      ...result,
      data: isLoading || !data ? undefined : transformFn(data[field]),
    });
  }, [result]);
  return resultWithLoading;
};
