import { Locale } from 'date-fns';
import { fr, nl } from 'date-fns/locale';
import { useLinguiLocale } from '../useLinguiLocale';

const locales = {
  fr,
  nl,
};

export const useGetDateFnsLocale = (): Locale => {
  const locale = useLinguiLocale();
  return locales[locale] ?? locales.fr;
};
