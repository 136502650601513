import {
  C_COUNTRY,
  C_TIT,
  dataStructure,
  ECPL_PROF,
  ECPL_SECTOR,
  makeName,
  MC,
  T_ADD_FAMILY_LINK,
  T_NATURE_LRP,
  T_PROC_TYPE,
  UncastedDataStructure,
  UncastedPhysicalTAdd,
  CivilStatus,
  PersonType,
  TAddType,
  CPhone,
  TNatureLrp,
  CTit,
  CCountry,
  Pep,
  MarStatut,
} from '@goldwasserexchange/oblis-frontend-utils';
import memoize from 'memoizee';
import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import {
  reject,
} from 'ramda';
import { useMemo } from 'react';
import ouiBase from '@goldwasserexchange/oui';
import { Locale } from '@goldwasserexchange/i18n';
import { useHookstate } from '@hookstate/core';
import { I18n } from '@lingui/core';
import { dateFormatter } from '../../../../OUI-dom/Inputs';
import {
  tAddName1Label, tAddName1ValidatorLogic, tAddSignificantOtherName1Label,
} from '../../inputs/name1';
import {
  tAddBirthPlaceLabel, tAddBirthPlaceValidatorLogic,
} from '../../inputs/birthplace';
import {
  tAddCityLabel,
} from '../../inputs/city';
import {
  TAddBirthdateLabel, TAddBirthdateSignificantOtherLabel, tAddBirthdateValidatorLogic,
} from '../../inputs/birthdate';
import {
  tAddFamilyLinkLabel, tAddFamilyLinkValidatorLogic,
} from '../../inputs/familyLink';
import {
  tAddFirstnameLabel, tAddFirstNameValidatorLogic, tAddSignificantOtherFirstnameLabel,
} from '../../inputs/firstName';
import {
  tAddFundsOriginLabel, tAddFundsOriginData, tAddFundsOriginValidatorLogic,
} from '../../inputs/fundsOrigin';
import {
  tAddCTitLabel, tAddIdCTitValidatorLogic,
} from '../../inputs/idCTit';
import {
  tAddStreet1Label,
} from '../../inputs/street1';
import {
  tAddCountryLabel,
} from '../../inputs/idCCountry';
import {
  tAddCountryNatLabel, tAddIdCCountryNatValidatorLogic,
} from '../../inputs/idCCountryNat';
import {
  tAddIdCCountryBirthLabel,
  tAddIdCCountryBirthValidatorLogic,
} from '../../inputs/idCCountryBirth';
import {
  tAddYnUsLabel, tAddYnUsHelpPopper, tAddYnUsValidatorLogic,
} from '../../inputs/ynUs';
import {
  tAddZipLabel,
} from '../../inputs/zip';
import {
  GeDataTables,
  GeElementFieldPathHooks,
  GeFormatters,
  GeColumns,
  T_ADD_TABLE_NAME,
  CUSTOM_TABLE_NAME,
  GeTables,
  C_COUNTRY_TABLE_NAME,
  T_ADD_FAMILY_LINK_NAME,
  FUND_ORIGIN_NAME,
  C_TIT_NAME,
  ECPL_PROF_TABLE_NAME,
  ECPL_SECTOR_TABLE_NAME,
  T_NATURE_LRP_TABLE_NAME,
  T_PROC_TYPE_TABLE_NAME,
  MC_CIVIL_STATUS_TABLE_NAME,
  UBO_TABLE_NAME,
  MANDATARY_TABLE_NAME,
  MC_PEP_NAME,
  MC_MAR_STATUT_NAME,
} from '../types';
import {
  useElementFieldPathHookWithParentAndDataFieldPath, useElementFieldPathHookWithParentFieldPath, useElementFieldPathHookWithRootAndDataFieldPath, useElementFieldPathHookWithRootFieldPath,
} from '../hooks';
import {
  useFirstSearchKey, useGetGetFn, useLastFieldPathPart,
} from '../../../../OUI/Inputs';
import {
  tAddMoralLeiCodeLabel, tAddMoralLeiCodeHelpText, tAddMoralVatLabel, tAddMoralVatHelpText, tAddMoralLeiCodeValidatorLogic, tAddMoralVatCodeValidatorLogic,
} from '../../inputs/moralPerson';
import {
  tAddProfessionLabel,
  hadProfessionLabel,
  tAddIdEcpliProfValidatorLogic,
  tAddHadProfessionValidatorLogic,
} from '../../inputs/idEcplProf';
import {
  lastIdEcplProfLabel,
  tAddLastIdEcplProfValidatorLogic,
} from '../../inputs/idEcplProf/lastProfession';
import {
  lastProfessionDateLabelText,
  tAddLastProfessionDateValidatorLogic,
} from '../../inputs/idEcplProf/lastProfession/lastProfessionDate';
import {
  tAddIdEcplSectorLabel,
  tAddIdEcplSectorValidatorLogic,
} from '../../inputs/idEcplSector';
import {
  tAddTNatureLrpLabel, tAddTNatureLrpInfoPopper,
} from '../../inputs/idTNatureLrp';
import {
  tAddTNatureLrpValidatorLogic,
} from '../../inputs/idTNatureLrp/logic';
import {
  tAddTProcTypeLabel, tAddTProcTypeInfoPopper, procFilter, tAddIdTProcTypeValidatorLogic,
} from '../../inputs/idTProcType';
import {
  tAddOtherConstraintsLabel, tAddOtherConstraintsValidatorLogic,
} from '../../inputs/marketReglementation/otherConstraints';
import {
  tAddMarketReglementationTypeData,
  tAddMarketReglementationTypeHelpPopper,
  tAddMarketReglementationTypeLabel,
  tAddMarketRegulationTypeValidatorLogic,
} from '../../inputs/marketReglementation/checkboxes';
import { tAddMcCivilStatusLabel } from '../../inputs/mcCivilStatus/label';
import {
  hasSameProfessionAndResidencyCountryLabel,
  showTAddHasSameProfessionAndResidencyCountryLogic,
  tAddHasSameProfessionAndResidencyCountryValidatorLogic,
} from '../../inputs/professionCountry/hasSameProfessionAndResidencyCountry';
import {
  idCCountryProfessionLabel,
  tAddIdCCountryProfessionValidatorLogic,
} from '../../inputs/professionCountry/idCCountryProfession';
import {
  tAddMoralBeneficiariesControlTypeLabel,
  tAddMoralBeneficiariesControlTypeValidatorLogic,
  tAddMoralBeneficiariesData,
} from '../../inputs/moralPerson/beneficiaries';
import {
  tAddMandataryLabel,
  tAddMandatarydata,
  tAddMandatarySpecifyLabel,
  tAddMandataryValidatorLogic,
  tAddSpecifyMandataryValidatorLogic,
} from '../../inputs/moralPerson/mandataries';
import {
  tAddFiscalResidencySameAsResidencyLabel,
  onlyOneFiscalResidencyLabel,
  tAddNationalRegisterLabel,
  tAddFiscRegistNumLabel,
  tAddFiscOtherRegistNumLabel,
  tAddNationalRegisterHelpText,
  tAddFiscRegistNumOtherValidatorLogic,
  tAddFiscalResidencySameAsResidencyValidatorLogic,
  tAddOnlyOneFiscalResidencyValidatorLogic,
  tAddNationalRegisterValidatorLogic,
  tAddFiscRegistNumValidatorLogic,
} from '../../inputs/fiscal';
import {
  tAddPepTypeLabel,
  McPepData,
  tAddPepTypeHelpPopper,
  tAddPepTypeValidatorLogic,
} from '../../inputs/pep/checkboxes';
import {
  tAddPhoneEmailLabel,
  tAddIdTAddPhoneValidatorLogic,
} from '../../inputs/idTAddPhone';
import {
  TAddIsTRelMail,
  tAddIsTRelMailValidatorLogic,
  tAddIsTRelMailVisibleLogic,
} from '../../inputs/isTRelMail';
import {
  IdTAddAddressDropDown, idTAddAddressValidatorLogic,
} from '../../inputs/address';
import {
  tAddProfessionDescriptionLabel, tAddProfessionDescriptionValidatorLogic,
} from '../../inputs/professionDescription';
import {
  tAddPhoneGsmLabel,
  tAddTelPrincipalLabel,
} from '../../../tAddPhone';
import { tAddPhonePhoneNumberHelpText } from '../../../tAddPhone/inputs/phone/phoneHelpText';
import { tAddYNPayPrecLabel } from '../../inputs';
import { useTAddPhoneMakeDependenciesFieldPaths } from '../../../tAddPhone/inputs/phone/validator';
import { SearchPlaceSetAddress } from '../../../SearchPlaceInput';
import { NoRender } from '../../../noRender';
import { useUser, useUserFromParentName } from '../../utils';

const useLmlSort = (ids) => {
  const firstSearchKey = useFirstSearchKey();
  const getFn = useGetGetFn();
  const sorted = useMemo(() => ids.sort((prevId, nextId) => {
    const prevLabel = getFn(prevId, firstSearchKey) ?? '';
    const nextLabel = getFn(nextId, firstSearchKey) ?? '';
    return prevLabel.localeCompare(nextLabel);
  }), [ids, firstSearchKey, getFn]);
  return sorted;
};

const addressPath: keyof Pick<UncastedPhysicalTAdd, 'address'> = 'address';

const fiscalAddressPath: keyof Pick<UncastedPhysicalTAdd, 'fiscalAddress'> = 'fiscalAddress';

const secondaryFiscalAddressPath: keyof Pick<UncastedPhysicalTAdd, 'secondaryFiscalAddress'> = 'secondaryFiscalAddress';

type AddresPathInputProps = {
  addressPath: (keyof Pick<UncastedPhysicalTAdd, 'address'>) | (keyof Pick<UncastedPhysicalTAdd, 'fiscalAddress'>) | (keyof Pick<UncastedPhysicalTAdd, 'secondaryFiscalAddress'>),
}

const addressPathInputProps: AddresPathInputProps = {
  addressPath,
};

const fiscalAddressPathInputProps: AddresPathInputProps = {
  addressPath: fiscalAddressPath,
};

const secondaryFiscalAddressPathInputProps: AddresPathInputProps = {
  addressPath: secondaryFiscalAddressPath,
};

const CTitMoralKeys = ['61', '98', '200', '201'];
const CTitPhysicKeys = ['63', '24', '16'];

const sortCountries = memoize((ids: string[], firstSearchKey: string, getFn: (id: string, key: string) => string) => ids.sort((prevId, nextId) => {
  if (prevId !== nextId) {
    if (prevId === CCountry.BELGIUM) {
      return -1;
    }
    if (nextId === CCountry.BELGIUM) {
      return 1;
    }
  }
  const prevLabel = getFn(prevId, firstSearchKey) ?? '';
  const nextLabel = getFn(nextId, firstSearchKey) ?? '';
  return prevLabel.localeCompare(nextLabel);
}));

type GraphQLDataListElement = {
  cursor: string,
  node: {
    'ID': number,
    'LML_NATIONALITY': string,
  } | {
    'ID': number,
    'LML_DESCRIPTION': string,
  },
}

type GraphQLDataList = {
  edges: GraphQLDataListElement[],
}

const graphQlDataListToGraphQlDataTable = (dataList: GraphQLDataList) => {
  const {
    edges,
  } = dataList;
  return edges.reduce((acc, edge) => ({ ...acc, [`${edge.node.ID}`]: edge.node }), {});
};

const userOrMoralPath = (pathString: string): ['onboarding', 'users', number] | ['onboarding', 'moralPerson'] => {
  const pathStringParts = pathString.split('.');
  if (pathStringParts[1] === 'users') {
    return [
      'onboarding',
      'users',
      parseInt(pathStringParts[2], 10),
    ];
  }
  if (pathStringParts[1] === 'moralPerson') {
    return [
      'onboarding',
      'moralPerson',
    ];
  }
  throw new Error(`path ${pathString} is not a user path`);
};

const dataFromMc = (data: Record<string, string>) => Object.fromEntries(Object.entries(data).map(([key, LML_DESCRIPTION]) => [key.replace('ID_', ''), { LML_DESCRIPTION }]));

export const geDataTables: GeDataTables = {
  familyLink: {
    id: 'familyLink',
    searchKeys: ['LML_DESCRIPTION'],
    tableName: T_ADD_FAMILY_LINK_NAME,
    data: graphQlDataListToGraphQlDataTable(T_ADD_FAMILY_LINK.IdLmlDescriptionInput),
  },
  cTit: {
    id: 'cTit',
    searchKeys: ['LML_DESCRIPTION'],
    tableName: C_TIT_NAME,
    data: graphQlDataListToGraphQlDataTable({
      edges: [
        ...C_TIT.IdLmlDescriptionInputPhysicPerson.edges,
        ...C_TIT.IdLmlDescriptionInput.edges,
      ],
    }),
    useFilterFn: (ids) => {
      const userState = useUser();
      const tAddTypeState = useHookstate(
        userState.T_ADD_type,
      );
      const isMoral = tAddTypeState.get() === TAddType.MORAL;
      const filtered = useMemo(() => ids.filter((id) => {
        if (isMoral) {
          return CTitMoralKeys.includes(id);
        }
        return CTitPhysicKeys.includes(id);
      }), [ids, isMoral, CTitMoralKeys, CTitPhysicKeys]);
      return filtered;
    },
  },
  countryName: {
    id: 'countryName',
    tableName: C_COUNTRY_TABLE_NAME,
    searchKeys: ['LML_DESCRIPTION'],
    data: graphQlDataListToGraphQlDataTable(C_COUNTRY.IdLmlDescriptionInput),
    useSortFn: (ids) => {
      const firstSearchKey = useFirstSearchKey();
      const getFn = useGetGetFn();
      const list = sortCountries(ids, firstSearchKey, getFn as (id: string, key: string) => string);
      return list;
    },
  },
  countryNationality: {
    id: 'countryNationality',
    tableName: C_COUNTRY_TABLE_NAME,
    searchKeys: ['LML_NATIONALITY'],
    data: graphQlDataListToGraphQlDataTable(C_COUNTRY.IdLmlNationalityInput),
  },
  EcplProfLml: {
    id: 'EcplProfLml',
    tableName: ECPL_PROF_TABLE_NAME,
    searchKeys: ['LML_DESCRIPTION'],
    data: graphQlDataListToGraphQlDataTable(ECPL_PROF.IdLmlDescriptionInput),
    useFilterFn: (ids) => {
      const lastFieldPath = useLastFieldPathPart();
      const lastIdEcplProfKey: keyof Pick<UncastedPhysicalTAdd, 'ID_ECPL_PROF_LAST'> = 'ID_ECPL_PROF_LAST';
      const filteredIds = useMemo(() => {
        if (lastFieldPath === lastIdEcplProfKey) {
          const withReject = reject<string>((id) => dataStructure.T_ADD.fields.ID_ECPL_PROF.notWorkingProfession.includes(parseInt(id, 10)))(ids);
          return withReject;
        }
        return ids;
      }, [ids, lastFieldPath]);
      return filteredIds;
    },
    useSortFn: useLmlSort,
  },
  EcplSectorLml: {
    id: 'EcplSectorLml',
    tableName: ECPL_SECTOR_TABLE_NAME,
    searchKeys: ['LML_DESCRIPTION'],
    data: graphQlDataListToGraphQlDataTable(ECPL_SECTOR.IdLmlDescriptionInput),
    useSortFn: useLmlSort,
  },
  TNatureLrp: {
    id: 'TNatureLrp',
    tableName: T_NATURE_LRP_TABLE_NAME,
    searchKeys: ['LML_DESCRIPTION'],
    data: graphQlDataListToGraphQlDataTable(T_NATURE_LRP.IdLmlDescriptionInput),
    useFilterFn: () => {
      const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
      const personTypeState = useHookstate(valueState.onboarding.personType);
      const isNotDivided = personTypeState.get() !== PersonType.DIVIDED;
      return isNotDivided ? dataStructure.T_ADD.fields.ID_T_NATURE_LRP.physicList : dataStructure.T_ADD.fields.ID_T_NATURE_LRP.usufruitierNuProprietaireList;
    },
  },
  TProcType: {
    id: 'TProcType',
    tableName: T_PROC_TYPE_TABLE_NAME,
    searchKeys: ['LML_DESCRIPTION'],
    data: graphQlDataListToGraphQlDataTable(T_PROC_TYPE.IdLmlDescriptionInput),
    useSortFn: useLmlSort,
    useFilterFn: (ids) => {
      const userState = useUser();
      const idTNatureLrpState = useHookstate(userState.ID_T_NATURE_LRP);
      const isTitulaire = idTNatureLrpState.get() === TNatureLrp.TITULAIRE;
      const isMinorState = useHookstate(userState.isMinor);
      const isMinor = isMinorState.get();
      const currentState = useHookstate(userState.current);
      const isCurrentUser = currentState.get() === true;
      return useMemo(() => procFilter({
        ids,
        isTitulaire,
        isCurrentUser,
        isMinor,
      }), [ids, isCurrentUser, isTitulaire, isMinor]);
    },
  },
  mcCivilStatus: {
    id: 'mcCivilStatus',
    data: dataFromMc(MC.MCCivilstatusLmlStrings),
    tableName: MC_CIVIL_STATUS_TABLE_NAME,
    searchKeys: ['LML_DESCRIPTION'],
    useFilterFn: (ids) => {
      const castedIds = ids as CivilStatus[];
      const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
      const personTypeState = useHookstate(valueState.onboarding.personType);
      if (personTypeState.get() === PersonType.JOINT) {
        return castedIds.filter((id) => dataStructure.T_ADD.fields.MC_CIVILSTATUS.hasSignificantOtherList.includes(id));
      }
      return ids;
    },
  },
  fundsOrigin: {
    id: 'fundsOrigin',
    data: tAddFundsOriginData,
    tableName: FUND_ORIGIN_NAME,
  },
  MC_PEP: {
    id: 'MC_PEP',
    data: McPepData,
    tableName: MC_PEP_NAME,
    searchKeys: ['value'],
    useSortFn: () => [Pep.NON, Pep.LIE, Pep.ASSOCIE, Pep.OUI],
  },
  MC_MAR_STATUT: {
    id: 'MC_MAR_STATUT',
    data: tAddMarketReglementationTypeData,
    tableName: MC_MAR_STATUT_NAME,
    searchKeys: ['value'],
    useSortFn: () => [MarStatut.AUCUN, MarStatut.PCA, MarStatut.PDMR],
  },
  ubo: {
    id: 'ubo',
    data: tAddMoralBeneficiariesData,
    tableName: UBO_TABLE_NAME,
    searchKeys: ['value'],
    useFilterFn: (ids) => {
      const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
      const socialForm = useHookstate(valueState.onboarding.moralPerson.ID_C_TIT).get();
      const user = useUser();
      const userType = useHookstate(user.T_ADD_type);
      const getFn = useGetGetFn();
      const results = ids.filter((id) => {
        if (id === '0' && userType.get() === TAddType.UBO) {
          return false;
        }
        const elementSocialForms = (getFn(id, 'socialForm') as unknown as CTit[]) ?? [0];
        return elementSocialForms?.includes(parseInt(socialForm, 10));
      });
      return results;
    },
  },
  mandataryFunction: {
    id: 'mandataryFunction',
    data: tAddMandatarydata,
    tableName: MANDATARY_TABLE_NAME,
    searchKeys: ['value'],
  },
};

export const geElementFieldPathHooks: GeElementFieldPathHooks = {
  WithRootFieldPath: {
    id: 'WithRootFieldPath',
    useElementFieldPathHook: useElementFieldPathHookWithRootFieldPath,
  },
  WithParentFieldPath: {
    id: 'WithParentFieldPath',
    useElementFieldPathHook: useElementFieldPathHookWithParentFieldPath,
  },
  WithParentAndDataFieldPath: {
    id: 'WithParentAndDataFieldPath',
    useElementFieldPathHook: useElementFieldPathHookWithParentAndDataFieldPath,
  },
  WithRootAndDataFieldPath: {
    id: 'WithRootFieldPath',
    useElementFieldPathHook: useElementFieldPathHookWithRootAndDataFieldPath,
  },
  WithPrimaryKey: {
    id: 'WithPrimaryKey',
    useElementFieldPathHook: ouiBase.utils.dimensions.useCurrentPrimaryDimension,
  },
};

export const geFormatters: GeFormatters = {
  dateFormatter: {
    id: 'dateFormatter',
    formatter: dateFormatter,
  },
};

export const geTables: GeTables = {
  [T_ADD_TABLE_NAME]: {
    id: T_ADD_TABLE_NAME,
    name: T_ADD_TABLE_NAME,
  },
  [CUSTOM_TABLE_NAME]: {
    id: CUSTOM_TABLE_NAME,
    name: CUSTOM_TABLE_NAME,
  },
  [C_COUNTRY_TABLE_NAME]: {
    id: C_COUNTRY_TABLE_NAME,
    name: C_COUNTRY_TABLE_NAME,
  },
};

const textOrComponentRepresenter = (_locale: Locale, i18n: I18n, v: any) => {
  if (typeof v === 'object' && 'id' in v) {
    return i18n._(v);
  }
  return v;
};

export const geTAddColumnTypeRepresentations: Record<
  string,
  {
    id: string,
    representer?: (locale: Locale, i18n: I18n, v: any) => any,
  }
> = {
  lml: {
    id: 'lml',
    representer: (locale: Locale, _i18n: I18n, v) => {
      if (locale === Locale.NL) {
        return lmlGet(1, v);
      }
      return lmlGet(0, v);
    },
  },
  Text: {
    id: 'Text',
    representer: textOrComponentRepresenter,
  },
  TextOrComponent: {
    id: 'TextOrComponent',
    representer: textOrComponentRepresenter,
  },
  id: {
    id: 'id',
  },
};

const makeUseTAddPhoneFieldPath = (shortcut) => (parentName) => {
  const usersState = useUserFromParentName(parentName);
  const phonesState = useHookstate(usersState.PHONE);
  const phoneIndex = dataStructure.T_ADD.fields.PHONE.fields.hooks.getPhoneIndexByCPhoneShortCut(shortcut, phonesState.get());
  return phonesState[phoneIndex].PHONE.path.join('.');
};

const geTAddColumns: GeColumns<Record<string, unknown>> = {
  BIRTH_DATE: {
    id: 'BIRTH_DATE',
    type: 'date',
    label: TAddBirthdateLabel,
    inputType: 'Text',
    formatter: 'dateFormatter',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.BIRTH_DATE.logic.show.showLogic,
    },
    validators: {
      logic: tAddBirthdateValidatorLogic,
    },
  },
  BIRTH_PLACE: {
    id: 'BIRTH_PLACE',
    type: 'string',
    label: tAddBirthPlaceLabel,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.BIRTH_PLACE.logic.show.showLogic,
    },
    validators: {
      logic: tAddBirthPlaceValidatorLogic,
    },
  },
  adressSelector: {
    id: 'adressSelector',
    type: 'string',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.address.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
    Input: SearchPlaceSetAddress,
    inputProps: addressPathInputProps,
  },
  T_ADD_ADDRESS_STREET_1: {
    id: 'T_ADD_ADDRESS_STREET_1',
    type: 'string',
    label: tAddStreet1Label,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${addressPath}.STREET_1`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.address.fields.STREET_1.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_ADDRESS_ZIP: {
    id: 'T_ADD_ADDRESS_ZIP',
    type: 'string',
    label: tAddZipLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${addressPath}.ZIP`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.address.fields.ZIP.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_ADDRESS_CITY: {
    id: 'T_ADD_ADDRESS_CITY',
    type: 'string',
    label: tAddCityLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${addressPath}.CITY`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.address.fields.CITY.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_ADDRESS_ID_C_COUNTRY: {
    id: 'T_ADD_ADDRESS_ID_C_COUNTRY',
    type: 'id',
    label: tAddCountryLabel,
    inputType: 'Select',
    dataTable: 'countryName',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${addressPath}.ID_C_COUNTRY`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.address.fields.ID_C_COUNTRY.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  ID_T_ADD_FAMILY_LINK: {
    id: 'ID_T_ADD_FAMILY_LINK',
    type: 'id',
    label: tAddFamilyLinkLabel,
    inputType: 'Select',
    dataTable: 'familyLink',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_T_ADD_FAMILY_LINK.logic.show.showLogic,
    },
    validators: {
      logic: tAddFamilyLinkValidatorLogic,
    },
  },
  FIRSTNAME: {
    id: 'FIRSTNAME',
    type: 'string',
    label: tAddFirstnameLabel,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.FIRSTNAME.logic.show.showLogic,
    },
    validators: {
      logic: tAddFirstNameValidatorLogic,
    },
  },
  fundsOrigin: {
    id: 'fundsOrigin',
    type: 'foreign',
    label: tAddFundsOriginLabel,
    dataTable: 'fundsOrigin',
    inputType: 'CheckboxList',
    elementFieldPathHook: 'WithRootAndDataFieldPath',
    elementFieldPathsFn: (parentName) => Object.values(tAddFundsOriginData).map((fundOrigin) => makeName(parentName, fundOrigin.fieldPath)),
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.FUNDS_ORIGIN.logic.show.showLogic,
    },
    validators: {
      logic: tAddFundsOriginValidatorLogic,
    },
  },
  ID_C_COUNTRY_BIRTH: {
    id: 'ID_C_COUNTRY_BIRTH',
    type: 'id',
    label: tAddIdCCountryBirthLabel,
    inputType: 'Select',
    dataTable: 'countryName',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_C_COUNTRY_BIRTH.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdCCountryBirthValidatorLogic,
    },
  },
  ID_C_COUNTRY_NAT: {
    id: 'ID_C_COUNTRY_NAT',
    type: 'id',
    label: tAddCountryNatLabel,
    inputType: 'Select',
    // TODO: use the demonym instead of the country name
    dataTable: 'countryName',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_C_COUNTRY_NAT.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdCCountryNatValidatorLogic,
    },
  },
  ID_C_TIT: {
    id: 'ID_C_TIT',
    type: 'id',
    label: tAddCTitLabel,
    inputType: 'RadioList',
    dataTable: 'cTit',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_C_TIT.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdCTitValidatorLogic,
    },
  },
  NAME_1: {
    id: 'NAME_1',
    type: 'string',
    label: tAddName1Label,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.NAME_1.logic.show.showLogic,
    },
    validators: {
      logic: tAddName1ValidatorLogic,
    },
  },
  'YN_PAY_PREC ': {
    id: 'YN_PAY_PREC ',
    type: 'boolean',
    label: tAddYNPayPrecLabel,
    inputType: 'YesNo',
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  YN_US: {
    id: 'YN_US',
    type: 'boolean',
    label: tAddYnUsLabel,
    inputType: 'YesNo',
    helpPopperContent: tAddYnUsHelpPopper,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.YN_US.logic.show.showLogic,
    },
    validators: {
      logic: tAddYnUsValidatorLogic,
    },
    useSortFn: () => ['0', '1'],
  },
  MIFID_ID: {
    id: 'MIFID_ID',
    type: 'string',
    label: tAddMoralLeiCodeLabel,
    inputType: 'Text',
    helpText: tAddMoralLeiCodeHelpText,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.MIFID_ID.logic.show.showLogic,
    },
    validators: {
      logic: tAddMoralLeiCodeValidatorLogic,
    },
  },
  REGIST_NUM: {
    id: 'REGIST_NUM',
    type: 'string',
    label: tAddMoralVatLabel,
    inputType: 'Text',
    helpText: tAddMoralVatHelpText,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.TVA_NUMBER.logic.show.showLogic,
    },
    validators: {
      logic: tAddMoralVatCodeValidatorLogic,
    },
  },
  ID_ECPL_PROF: {
    id: 'ID_ECPL_PROF',
    type: 'id',
    label: tAddProfessionLabel,
    inputType: 'Select',
    dataTable: 'EcplProfLml',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_ECPL_PROF.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdEcpliProfValidatorLogic,
    },
  },
  ID_ECPL_PROF_LAST: {
    id: 'ID_ECPL_PROF_LAST',
    type: 'id',
    label: lastIdEcplProfLabel,
    inputType: 'Select',
    dataTable: 'EcplProfLml',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_ECPL_PROF_LAST.logic.show.showLogic,
    },
    validators: {
      logic: tAddLastIdEcplProfValidatorLogic,
    },
  },
  DATE_CESS: {
    id: 'DATE_CESS',
    type: 'id',
    label: lastProfessionDateLabelText,
    inputType: 'Text',
    formatter: 'dateFormatter',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.DATE_CESS.logic.show.showLogic,
    },
    validators: {
      logic: tAddLastProfessionDateValidatorLogic,
    },
  },
  HadProfession: {
    id: 'HadProfession',
    type: 'boolean',
    label: hadProfessionLabel,
    inputType: 'YesNo',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.HAD_PROFESSION.logic.show.showLogic,
    },
    validators: {
      logic: tAddHadProfessionValidatorLogic,
    },
  },
  ID_ECPL_SECTOR: {
    id: 'ID_ECPL_SECTOR',
    type: 'id',
    label: tAddIdEcplSectorLabel,
    inputType: 'Select',
    dataTable: 'EcplSectorLml',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_ECPL_SECTOR.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdEcplSectorValidatorLogic,
    },
  },
  ID_T_NATURE_LRP: {
    id: 'ID_T_NATURE_LRP',
    type: 'id',
    label: tAddTNatureLrpLabel,
    inputType: 'RadioList',
    dataTable: 'TNatureLrp',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_T_NATURE_LRP.logic.show.showLogic,
    },
    validators: {
      logic: tAddTNatureLrpValidatorLogic,
    },
    helpPopperContent: tAddTNatureLrpInfoPopper,
  },
  ID_T_PROC_TYPE: {
    id: 'ID_T_PROC_TYPE',
    type: 'id',
    label: tAddTProcTypeLabel,
    inputType: 'Select',
    dataTable: 'TProcType',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_T_PROC_TYPE.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdTProcTypeValidatorLogic,
    },
    helpPopperContent: tAddTProcTypeInfoPopper,
  },
  MC_MAR_STATUT: {
    id: 'MC_MAR_STATUT',
    type: 'id',
    label: tAddMarketReglementationTypeLabel,
    dataTable: 'MC_MAR_STATUT',
    inputType: 'RadioList',
    helpPopperContent: tAddMarketReglementationTypeHelpPopper,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.MC_MAR_STATUT.logic.show.showLogic,
    },
    validators: {
      logic: tAddMarketRegulationTypeValidatorLogic,
    },
  },
  otherConstraints: {
    id: 'otherConstraints',
    type: 'boolean',
    label: tAddOtherConstraintsLabel,
    inputType: 'YesNo',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.OTHER_CONSTRAINTS_KEY.logic.show.showLogic,
    },
    validators: {
      logic: tAddOtherConstraintsValidatorLogic,
    },
    useSortFn: () => ['0', '1'],
  },
  MC_CIVILSTATUS: {
    id: 'MC_CIVILSTATUS',
    type: 'id',
    label: tAddMcCivilStatusLabel,
    inputType: 'Select',
    dataTable: 'mcCivilStatus',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.MC_CIVILSTATUS.logic.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  MC_PEP: {
    id: 'MC_PEP',
    type: 'id',
    label: tAddPepTypeLabel,
    dataTable: 'MC_PEP',
    inputType: 'RadioList',
    helpPopperContent: tAddPepTypeHelpPopper,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.MC_PEP.logic.show.showLogic,
    },
    validators: {
      logic: tAddPepTypeValidatorLogic,
    },
  },
  HasSameProfessionAndResidencyCountry: {
    id: 'HasSameProfessionAndResidencyCountry',
    type: 'boolean',
    label: hasSameProfessionAndResidencyCountryLabel,
    inputType: 'YesNo',
    fieldFilterLogic: {
      logic: showTAddHasSameProfessionAndResidencyCountryLogic,
    },
    validators: {
      logic: tAddHasSameProfessionAndResidencyCountryValidatorLogic,
    },
  },
  ID_C_COUNTRY_ACTI: {
    id: 'ID_C_COUNTRY_ACTI',
    type: 'id',
    label: idCCountryProfessionLabel,
    inputType: 'Select',
    dataTable: 'countryName',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_C_COUNTRY_ACTI.logic.show.showLogic,
    },
    validators: {
      logic: tAddIdCCountryProfessionValidatorLogic,
    },
  },
  ID_T_ACC_AYDR: {
    id: 'ID_T_ACC_AYDR',
    type: 'id',
    label: tAddMoralBeneficiariesControlTypeLabel,
    inputType: 'RadioList',
    dataTable: 'ubo',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_T_ACC_AYDR.logic.show.showLogic,
    },
    validators: {
      logic: tAddMoralBeneficiariesControlTypeValidatorLogic,
    },
  },
  ID_C_TIT_MORAL: {
    id: 'ID_C_TIT_MORAL',
    type: 'id',
    label: tAddMandataryLabel,
    inputType: 'Select',
    dataTable: 'mandataryFunction',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_C_TIT_MORAL.logic.show.showLogic,
    },
    validators: {
      logic: tAddMandataryValidatorLogic,
    },
  },
  ID_C_TIT_MORALPrecision: {
    id: 'ID_C_TIT_MORALPrecision',
    type: 'Text',
    label: tAddMandatarySpecifyLabel,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_C_TIT_MORALPrecision.logic.show.showLogic,
    },
    validators: {
      logic: tAddSpecifyMandataryValidatorLogic,
    },
  },
  fiscalResidencySameAsResidency: {
    id: 'fiscalResidencySameAsResidency',
    type: 'boolean',
    label: tAddFiscalResidencySameAsResidencyLabel,
    inputType: 'YesNo',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.FISCAL_RESIDENCY_SAME_AS_RESIDENCY_KEY.logic.show.showLogic,
    },
    validators: {
      logic: tAddFiscalResidencySameAsResidencyValidatorLogic,
    },
  },
  fiscalAdressSelector: {
    id: 'fiscalAdressSelector',
    type: 'string',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.fiscalAddress.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
    Input: SearchPlaceSetAddress,
    inputProps: fiscalAddressPathInputProps,
  },
  T_ADD_FISCAL_ADDRESS_STREET_1: {
    id: 'T_ADD_FISCAL_ADDRESS_STREET_1',
    type: 'string',
    label: tAddStreet1Label,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${fiscalAddressPath}.STREET_1`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.fiscalResidencySameAsResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.fiscalAddress.fields.STREET_1.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_FISCAL_ADDRESS_ZIP: {
    id: 'T_ADD_FISCAL_ADDRESS_ZIP',
    type: 'string',
    label: tAddZipLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${fiscalAddressPath}.ZIP`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.fiscalResidencySameAsResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.fiscalAddress.fields.ZIP.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_FISCAL_ADDRESS_CITY: {
    id: 'T_ADD_FISCAL_ADDRESS_CITY',
    type: 'string',
    label: tAddCityLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${fiscalAddressPath}.CITY`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.fiscalResidencySameAsResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.fiscalAddress.fields.CITY.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY: {
    id: 'T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY',
    type: 'id',
    label: tAddCountryLabel,
    inputType: 'Select',
    dataTable: 'countryName',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${fiscalAddressPath}.ID_C_COUNTRY`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.fiscalResidencySameAsResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.fiscalAddress.fields.ID_C_COUNTRY.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  onlyOneFiscalResidency: {
    id: 'onlyOneFiscalResidency',
    type: 'boolean',
    label: onlyOneFiscalResidencyLabel,
    inputType: 'YesNo',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ONLY_ONE_FISCAL_RESIDENCY.logic.show.showLogic,
    },
    validators: {
      logic: tAddOnlyOneFiscalResidencyValidatorLogic,
    },
  },
  secondaryFiscalAdressSelector: {
    id: 'fiscalAdressSelector',
    type: 'string',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.secondaryFiscalAddress.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
    Input: SearchPlaceSetAddress,
    inputProps: secondaryFiscalAddressPathInputProps,
  },
  T_ADD_SECONDARY_FISCAL_ADDRESS_STREET_1: {
    id: 'T_ADD_SECONDARY_FISCAL_ADDRESS_STREET_1',
    type: 'string',
    label: tAddStreet1Label,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${secondaryFiscalAddressPath}.STREET_1`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.onlyOneFiscalResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.secondaryFiscalAddress.fields.STREET_1.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_SECONDARY_FISCAL_ADDRESS_ZIP: {
    id: 'T_ADD_FISCAL_ADDRESS_ZIP',
    type: 'string',
    label: tAddZipLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${secondaryFiscalAddressPath}.ZIP`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.onlyOneFiscalResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.secondaryFiscalAddress.fields.ZIP.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_SECONDARY_FISCAL_ADDRESS_CITY: {
    id: 'T_ADD_FISCAL_ADDRESS_CITY',
    type: 'string',
    label: tAddCityLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${secondaryFiscalAddressPath}.CITY`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.onlyOneFiscalResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.secondaryFiscalAddress.fields.CITY.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_SECONDARY_FISCAL_ADDRESS_ID_C_COUNTRY: {
    id: 'T_ADD_FISCAL_ADDRESS_ID_C_COUNTRY',
    type: 'id',
    label: tAddCountryLabel,
    inputType: 'Select',
    dataTable: 'countryName',
    useMakeFieldPath: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return `${basePath.join('.')}.${secondaryFiscalAddressPath}.ID_C_COUNTRY`;
    },
    useMakeDependenciesFieldPaths: (parentName) => {
      const basePath = userOrMoralPath(parentName);
      return [`${basePath.join('.')}.onlyOneFiscalResidency`];
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.secondaryFiscalAddress.fields.ID_C_COUNTRY.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  nat_REGIST_NUM: {
    id: 'nat_REGIST_NUM',
    type: 'string',
    label: tAddNationalRegisterLabel,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.NAT_REGIST_NUM.logic.show.showLogic,
    },
    validators: {
      logic: tAddNationalRegisterValidatorLogic,
    },
    helpText: tAddNationalRegisterHelpText,
  },
  TIN_US: {
    id: 'TIN_US',
    type: 'string',
    label: tAddFiscRegistNumLabel,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.TIN_US.logic.show.showLogic,
    },
    validators: {
      logic: tAddFiscRegistNumValidatorLogic,
    },
  },
  TIN_US_SEC: {
    id: 'TIN_US_SEC',
    type: 'string',
    label: tAddFiscOtherRegistNumLabel,
    inputType: 'Text',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.TIN_US_SEC.logic.show.showLogic,
    },
    validators: {
      logic: tAddFiscRegistNumOtherValidatorLogic,
    },
  },
  T_ADD_EMAIL_PRINCIPAL: {
    id: 'T_ADD_EMAIL_PRINCIPAL',
    type: 'string',
    inputType: 'Text',
    label: tAddPhoneEmailLabel,
    useMakeFieldPath: makeUseTAddPhoneFieldPath(CPhone.EMAIL_PRINCIPAL),
    useMakeDependenciesFieldPaths: useTAddPhoneMakeDependenciesFieldPaths,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.PHONE.fields.PHONE.logic.show.showEmailPrincipalLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_GSM_PRINCIPAL: {
    id: 'T_ADD_GSM_PRINCIPAL',
    type: 'string',
    inputType: 'Text',
    label: tAddPhoneGsmLabel,
    helpText: tAddPhonePhoneNumberHelpText,
    useMakeFieldPath: makeUseTAddPhoneFieldPath(CPhone.GSM_PRINCIPAL),
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.PHONE.fields.PHONE.logic.show.showGsmPrincipalLogic,
    },
    useMakeDependenciesFieldPaths: useTAddPhoneMakeDependenciesFieldPaths,
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_TEL_PRINCIPAL: {
    id: 'T_ADD_TEL_PRINCIPAL',
    type: 'string',
    inputType: 'Text',
    label: tAddTelPrincipalLabel,
    helpText: tAddPhonePhoneNumberHelpText,
    useMakeFieldPath: makeUseTAddPhoneFieldPath(CPhone.TEL_PRINCIPAL),
    useMakeDependenciesFieldPaths: useTAddPhoneMakeDependenciesFieldPaths,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.PHONE.fields.PHONE.logic.show.showLandlineLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  significantOther: {
    id: 'significantOther',
    type: 'object',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.SIGNIFICANT_OTHER.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
    Input: NoRender,
    inputProps: {},
  },
  significantOther_FIRSTNAME: {
    id: 'significantOther_FIRSTNAME',
    type: 'string',
    label: tAddSignificantOtherFirstnameLabel,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const significantOther: keyof Pick<UncastedPhysicalTAdd, 'significantOther'> = 'significantOther';
      const firstNamePath: keyof Pick<UncastedPhysicalTAdd['significantOther'], 'FIRSTNAME'> = 'FIRSTNAME';
      return `${parentName}.${significantOther}.${firstNamePath}`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.SIGNIFICANT_OTHER_FIRSTNAME.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  significantOther_NAME1: {
    id: 'significantOther_NAME1',
    type: 'string',
    label: tAddSignificantOtherName1Label,
    inputType: 'Text',
    useMakeFieldPath: (parentName) => {
      const significantOther: keyof Pick<UncastedPhysicalTAdd, 'significantOther'> = 'significantOther';
      const name1Path: keyof Pick<UncastedPhysicalTAdd['significantOther'], 'NAME_1'> = 'NAME_1';
      return `${parentName}.${significantOther}.${name1Path}`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.SIGNIFICANT_OTHER_NAME_1.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN: {
    id: 'T_ADD_SIGNIFICANT_OTHER_BIRTH_DATE_COLUMN',
    type: 'date',
    label: TAddBirthdateSignificantOtherLabel,
    inputType: 'Text',
    formatter: 'dateFormatter',
    useMakeFieldPath: (parentName) => {
      const significantOther: keyof Pick<UncastedPhysicalTAdd, 'significantOther'> = 'significantOther';
      const birthdatePath: keyof Pick<UncastedPhysicalTAdd['significantOther'], 'BIRTH_DATE'> = 'BIRTH_DATE';
      return `${parentName}.${significantOther}.${birthdatePath}`;
    },
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.SIGNIFICANT_OTHER_BIRTH_DATE.logic.show.showLogic,
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  isTRelMail: {
    id: 'isTRelMail',
    type: 'boolean',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: tAddIsTRelMailVisibleLogic,
    },
    validators: {
      logic: tAddIsTRelMailValidatorLogic,
    },
    Input: TAddIsTRelMail,
    inputProps: {},
  },
  PHONE: {
    id: 'PHONE',
    type: 'boolean',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: tAddIdTAddPhoneValidatorLogic,
    },
    Input: NoRender,
    inputProps: {},
  },
  ID_T_ADD_ADDRESS_KEY: {
    id: 'ID_T_ADD_ADDRESS_KEY',
    type: 'id',
    inputType: 'Custom',
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.ID_T_ADD_ADDRESS.logic.show.showLogic,
    },
    validators: {
      logic: idTAddAddressValidatorLogic,
    },
    Input: IdTAddAddressDropDown,
    inputProps: {},
  },
  DESC_ECPL_SECTOR: {
    id: 'DESC_ECPL_SECTOR',
    type: 'string',
    inputType: 'Text',
    label: tAddProfessionDescriptionLabel,
    fieldFilterLogic: {
      logic: dataStructure.T_ADD.fields.DESC_ECPL_SECTOR.logic.show.showLogic,
    },
    validators: {
      logic: tAddProfessionDescriptionValidatorLogic,
    },
  },
};

const baseLmlDescription: GeColumns<Record<string, unknown>> = {
  LML_DESCRIPTION: {
    id: 'LML_DESCRIPTION',
    type: 'lml',
    inputType: 'Text',
    label: null,
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
};

export const geCCountryColumns = baseLmlDescription;

export const geCTit = baseLmlDescription;

export const geEcplProfColumns = baseLmlDescription;

export const geEcplSectorColumns = baseLmlDescription;

export const tNatureLrpColumns = baseLmlDescription;

export const tProcTypeColumns = baseLmlDescription;

export const mcCivilStatusColumns = baseLmlDescription;

export const tAddFamillyLinkColumns = baseLmlDescription;

export const uboColumns: GeColumns<Record<string, unknown>> = {
  value: {
    id: 'value',
    type: 'Text',
    inputType: 'Text',
    label: null,
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
  socialForm: {
    id: 'socialForm',
    type: 'id',
    inputType: 'Text',
    label: null,
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
};

export const mandataryColumns: GeColumns<Record<string, unknown>> = {
  value: {
    id: 'value',
    type: 'Text',
    inputType: 'Text',
    label: null,
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
};

export const marketReglementationColumns: GeColumns<Record<string, unknown>> = {
  value: {
    id: 'value',
    type: 'TextOrComponent',
    inputType: 'Text',
    label: null,
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
};

export const pepColumns: GeColumns<Record<string, unknown>> = {
  value: {
    id: 'value',
    type: 'TextOrComponent',
    inputType: 'Text',
    label: null,
    fieldFilterLogic: {
      logic: {
        result: 'visible',
      },
    },
    validators: {
      logic: {
        result: 'any',
      },
    },
  },
};

export const geColumns: GeColumns<Record<string, unknown>> = {
  ...(Object.fromEntries(Object.entries(geTAddColumns).map(([key, value]) => [makeName(T_ADD_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(geCCountryColumns).map(([key, value]) => [makeName(C_COUNTRY_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(geCTit).map(([key, value]) => [makeName(C_TIT_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(geEcplProfColumns).map(([key, value]) => [makeName(ECPL_PROF_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(geEcplSectorColumns).map(([key, value]) => [makeName(ECPL_SECTOR_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(tNatureLrpColumns).map(([key, value]) => [makeName(T_NATURE_LRP_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(tProcTypeColumns).map(([key, value]) => [makeName(T_PROC_TYPE_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(mcCivilStatusColumns).map(([key, value]) => [makeName(MC_CIVIL_STATUS_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(uboColumns).map(([key, value]) => [makeName(UBO_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(mandataryColumns).map(([key, value]) => [makeName(MANDATARY_TABLE_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(tAddFamillyLinkColumns).map(([key, value]) => [makeName(T_ADD_FAMILY_LINK_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(marketReglementationColumns).map(([key, value]) => [makeName(MC_MAR_STATUT_NAME, key), value]))),
  ...(Object.fromEntries(Object.entries(pepColumns).map(([key, value]) => [makeName(MC_PEP_NAME, key), value]))),
};
