import * as React from 'react';
import { Untracked } from '@hookstate/untracked';
import { useMemo, useCallback } from 'react';
import {
  makeName,
  dataStructure,
  UncastedDataStructure,
  UncastedPhysicalTAdd,
} from '@goldwasserexchange/oblis-frontend-utils';
import lmlGet from '@goldwasserexchange/utils/lib/lmlGet';
import { isNil } from 'ramda';
import { msg, Trans } from '@lingui/macro';
import { TopModel } from '@goldwasserexchange/react-topmodel';
import ouiBase from '@goldwasserexchange/oui';
import { useHookstate } from '@hookstate/core';
import { useLingui } from '@lingui/react';
import { SelectListInput } from '../../../OUI-dom/Inputs';
import { ParentNameProps } from '../../Form/utils';
import {
  useGetGetFn, useLastParentFieldPathPart,
} from '../../../OUI/Inputs';
import { geDataTables } from '../data';
import {
  OnChangeDefaultHandlerEnhanceContext, OnChangeEnhanceContextProvider,
} from '../../../OUI';
import { SearchKey } from '../../../OUI/Inputs/components/shared/components/list/context/searchKeys/context';
import { useLinguiLocale } from '../../useLinguiLocale';

export const idTAddAddressValidatorLogic = dataStructure.T_ADD.fields.TYPE.logic.makeTAddIsNotMoralOrIgnored<'requiredString' | 'nilCast'>({
  truthy: dataStructure.personType.logic.show.makeShowMoralField({
    visible: {
      result: 'requiredString',
    },
    invisible: {
      result: 'nilCast',
    },
  }),
  falsy: {
    result: 'nilCast',
  },
});

const useUserSelectGetAddressFn = () => {
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const users = useHookstate(valueState.onboarding.users);
  const {
    i18n,
  } = useLingui();
  const locale = useLinguiLocale();
  const getFn = useCallback((source: string | { ID: string }, path: SearchKey): string | undefined => {
    const primaryKey = typeof source === 'string' || source == null ? source : source.ID;
    if (isNil(primaryKey)) {
      return '';
    }
    const userState = users.nested(parseInt(primaryKey, 10));
    if (path === 'value') {
      if (primaryKey === 'new') {
        return i18n._(msg`Nouvelle adresse`);
      }
      const street = userState.address.STREET_1.get();
      const zip = userState.address.ZIP.get();
      const city = userState.address.CITY.get();
      const countryId = userState.address.ID_C_COUNTRY.get();
      const countryData = geDataTables.countryName.data[countryId];
      const LML_DESCRIPTION = (countryData && 'LML_DESCRIPTION' in countryData) ? countryData.LML_DESCRIPTION : undefined;
      return `${street ? `${street}, ` : ''}${zip ? `${zip} ` : ''}${city ? `${city}, ` : ''}${LML_DESCRIPTION ? lmlGet(0, LML_DESCRIPTION) : ''}`;
    }
    if (path === 'socialForm' || path === 'T_REL_FIN.NUM_FIN' || path === 'C_CURRENCY.CODE' || path === 'LML_DESCRIPTION' || path === 'LML_NATIONALITY') {
      return '';
    }
    const result = userState.nested(path).get();
    return typeof result === 'string' || result === undefined ? result : undefined;
  }, [locale]);
  return getFn;
};

const UserSelectListElementLabel = () => {
  const getFn = useGetGetFn();
  const primaryKey = ouiBase.utils.dimensions.useCurrentPrimaryDimension();
  const value = getFn(primaryKey, 'value');
  return value;
};

const userSelectListElementLabel = (
  <TopModel name="SelectInputListElementLabelContent">
    {UserSelectListElementLabel}
  </TopModel>
);

const useIdTAddAddressFilter = (list: string[]) => {
  const getFn = useGetGetFn();
  return list.filter((primaryKey) => {
    const value = getFn(primaryKey, 'value');
    return value !== '';
  });
};

const IdTAddAddressDropDownLabel = (
  <Trans>
    Veuillez choisir une option
  </Trans>
);

const idTAddAddressDropDownLabel = (
  <TopModel name="SelectInputLabelContent">
    {IdTAddAddressDropDownLabel}
  </TopModel>
);

const IdTAddAddressDropDownListElementOnChangeContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const usersState = useHookstate(valueState.onboarding.users);
  const currentUserIndex = useLastParentFieldPathPart();
  const onChange = useCallback((e) => {
    if (currentUserIndex) {
      const sourceUser = usersState.nested(e.target.value);
      const currentUser = usersState.nested(parseInt(currentUserIndex, 10));
      if (e.target.value !== '' && e.target.value !== 'new') {
        currentUser.address.STREET_1.set(sourceUser.address.STREET_1.get());
        currentUser.address.ZIP.set(sourceUser.address.ZIP.get());
        currentUser.address.CITY.set(sourceUser.address.CITY.get());
        currentUser.address.ID_C_COUNTRY.set(sourceUser.address.ID_C_COUNTRY.get());
      } else {
        currentUser.address.STREET_1.set('');
        currentUser.address.ZIP.set('');
        currentUser.address.CITY.set('');
        currentUser.address.ID_C_COUNTRY.set('');
      }
    }
  }, []);
  return (
    <OnChangeEnhanceContextProvider onChange={onChange}>
      <OnChangeDefaultHandlerEnhanceContext>
        {children}
      </OnChangeDefaultHandlerEnhanceContext>
    </OnChangeEnhanceContextProvider>
  );
};

const idTAddAddressDropDownListElementOnChangeContextProviderTopModel = (
  <TopModel name="SelectInputListElementOnChangeContextProvider">
    {IdTAddAddressDropDownListElementOnChangeContextProvider}
  </TopModel>
);

const idTAddAddress: keyof Pick<UncastedPhysicalTAdd, 'ID_T_ADD_ADDRESS_KEY'> = 'ID_T_ADD_ADDRESS_KEY';

export const IdTAddAddressDropDown = (props: ParentNameProps) => {
  const { parentName } = props;
  const valueState = ouiBase.Form.useFormData<UncastedDataStructure>();
  const usersState = useHookstate(valueState.onboarding.users);
  const list = useMemo(() => [
    'new',
    ...Untracked(usersState)
      .get()
      .reduce<{ id: number, address: string }[]>((acc, element, index) => {
        const address = JSON.stringify({
          STREET_1: element.address.STREET_1,
          ZIP: element.address.ZIP,
          CITY: element.address.CITY,
          ID_C_COUNTRY: element.address.ID_C_COUNTRY,
        });
        if (acc.some((el) => el.address === address)) {
          return acc;
        }
        return [...acc, { id: index, address }];
      }, [])
      .map((el) => `${el.id}`),
  ], [usersState.length]);
  return (
    <SelectListInput
      fieldPath={makeName(parentName, idTAddAddress)}
      list={list}
      searchKeys={['value']}
      useGetFn={useUserSelectGetAddressFn}
      useFilterFn={useIdTAddAddressFilter}
    >
      {idTAddAddressDropDownLabel}
      {userSelectListElementLabel}
      {idTAddAddressDropDownListElementOnChangeContextProviderTopModel}
    </SelectListInput>
  );
};
