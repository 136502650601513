import * as React from 'react';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';
import { useSecuritiesSecurityFullName } from '../../hooks';

export const SecuritiesSecurityFullname = () => {
  const {
    data,
    isLoading,
    error,
  } = useSecuritiesSecurityFullName();
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading}
      error={error}
    />
  );
};
