import { useSecuritiesSecurityTransformApi } from '../api';
import { getMcGrossOpbDivider } from '../sType';
import { SecuritiesSecurity } from '../../type';
import { getAccrued } from './calculator';
import Big from 'big.js';

export const getEstimator = (data: SecuritiesSecurity | undefined) => {
  if (data == null) {
    return undefined;
  }
  const estimateFn = (props: {price: number, quantity: number}) => {
    const {
      price,
      quantity,
    } = props;
    const divider = getMcGrossOpbDivider(data['S_TYPE.MC_GROSS_OPB']) ?? 1;
    const accrued = getAccrued(data);
    const bigPrice = Big(`${price}`);
    const bigDivider = Big(`${divider}`);
    const bigAccrued = Big(`${accrued ?? 0}`);
    const bigQuantity = Big(`${quantity}`);
    return bigPrice
      .div(bigDivider)
      .add(bigAccrued)
      .times(bigQuantity)
      .toNumber();
  };
  return estimateFn;
};

export const useSecuritiesSecurityEstimator = () => {
  const response = useSecuritiesSecurityTransformApi(getEstimator);
  return response;
};
